$(window).scroll(function() {
    if ($(this).scrollTop() > 100){  
        $("#header").addClass("sticky");
        $(".logo-light").fadeOut();
        $(".logo-dark").fadeIn();
        $(".navigation").addClass("sticky");
    }
    else{
        $("#header").removeClass("sticky");
        $(".logo-dark").fadeOut();
        $(".logo-light").fadeIn();
        $(".navigation").removeClass("sticky");
    }
});
